<template>
  <div class="payment-success">
    <div style="margin-left: 20px;">
      <div style="line-height: 20px;text-align: center;">
        <img src="../../public/battlo_head.png" style="width: 60%;margin-bottom: 20px;"/>
      </div>
      <div style="line-height: 30px;">
        <span style="width: 30%;float: left;">剧场名称：</span>
        <span style="width: 70%;float: right;overflow: hidden;text-align: right;">{{productName}}</span>
      </div>
      <div  style="line-height: 30px;">
        <span style="width: 30%;float: left;">订单状态：</span>
        <span style="width: 70%;float: right;text-align: right;">{{status}}</span>
      </div>
      <div  style="line-height: 30px;">
        <span style="width: 30%;float: left;">订单编号：</span>
        <span style="width: 70%;float: right;overflow: hidden;text-align: right;">{{orderId}}</span>
      </div>
      <div  style="line-height: 30px;">
        <span style="width: 30%;float: left;">支付总额：</span>
        <span style="width: 70%;float: right;text-align: right;">€{{amount}}</span>
      </div>

    </div>
    <div style="align-items: center;">
      <button style="width: 90%;" @click="goBackMp()">返回小程序</button>
    </div>


    <div style="line-height: 20px;text-align: center;background-color: #fff;">
      <img src="../../public/alipayplus.png" style="width: 60%;margin-bottom: 20px;"/>
    </div>
  </div>

</template>

<script>
	import axios from 'axios'
export default {
  name: 'paymentResult',
  mounted() {
	var url = window.location.href ; 
	var dz_url = url.split('#')[0];
	var cs = dz_url.split('?')[1];
	var cs_arr = cs.split('&');                    //参数字符串分割为数组
	var params={};
	for(var i=0;i<cs_arr.length;i++){         //遍历数组，拿到json对象
	  params[cs_arr[i].split('=')[0]] = cs_arr[i].split('=')[1]
	}
    this.orderId = params['orderId']
    this.queryOrder();
  },
  data() {
    return {
      orderId: '数据异常',
      amount: '0.00',
      orderObj: '',
      productName: '数据异常',
      status: '数据异常',
      productImg: '',
    };
  },
  methods: {
    queryOrder(){
      const url = "https://battlo.starpayes.com";
	  axios.post(url+'/gateway/confirmation/v1/con/payment/queryOrder', { orderId: this.orderId}).then(response => {
	    console.log("++++"+response.data)
		this.orderObj = response.data;
		this.amount = this.orderObj.amount
		this.productName = this.orderObj.productName
		this.productImg = this.orderObj.avatar
		console.log("++++"+this.productImg)
		if("SUCCESS" == this.orderObj.status){
		  this.status = "支付成功";
		}else if("FAIL" == this.orderObj.status){
		  this.status = "支付失败";
		}
		else{
		  this.status = "支付处理中";
		}
	  }).catch(error => {
	    console.log(error)
	  })
    },
    goBackMp(){
      // my.navigateToMiniProgram({
      //   appId: '2021004105621207', // 16 位
      //   path: 'pages/index/tabbar',
      //   query: {
      //     a: 'foo',
      //     b: 'bar',
      //   },
      //   extraData: {
      //     x: {
      //       y: 'z',
      //     },
      //   },
      //   success: function (res) {
      //     console.log(res);
      //   },
      //   fail: function (err) {
      //     console.log(err);
      //   }
      // });
	  window.location.href="alipays://platformapi/startapp?appId=2021004105621207&page=pages/index/tabbar";
    }
  }
}
</script>

<style scoped>
.payment-success {
  font-size: 14px;
  background-color: #fff;

}

.payment-success button {
  margin-top: 50px;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  background-color: #1778FF;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
  width: 90%;
}
.payment-success button:hover {
  background-color: #1753F9;
}
</style>
